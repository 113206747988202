import React from 'react'
import PropTypes from 'prop-types'
import Container from './Container'
import VehicleCard from './VehicleCard'
import Img from 'gatsby-image'
import styled from 'styled-components'
import _ from 'underscore'

const VehicleListingWrapper = styled.div`
  margin: 3rem 0;
  section {
    flex-wrap: wrap;
  }
`
const TitleWrapper = styled.div`
  section {
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    h2 {
      margin: 0;
      font-size: 30px;
      line-height: 30px;
      padding-left: 10px;
      padding-bottom: 0;
      padding-top: 7px;
    }
  }
`

class VehicleListing extends React.Component {
  static propTypes = {
    vehicles: PropTypes.array.isRequired,
    title: PropTypes.string,
    icon: PropTypes.object,
  }

  _renderTitle = () => {
    let { icon, title } = this.props
    if (title) {
      return (
        <TitleWrapper>
          <Container>
            <Img fixed={icon} />
            <h2>{title}</h2>
          </Container>
        </TitleWrapper>
      )
    }
  }

  _renderVehicleCards = () => {
    let { vehicles } = this.props
    return _.map(vehicles, (vehicle, index) => {
      let {
        vehicleImages,
        vehicleMake,
        vehicleModel,
        vehicleYear,
        vehicleName,
        active,
      } = vehicle.node.data
      let cardProps = { vehicleMake, vehicleModel, vehicleYear, vehicleName }
      if (active) {
        return (
          <VehicleCard
            key={index}
            image={
              vehicleImages
                ? vehicleImages.localFiles[0].childImageSharp.fluid
                : {}
            }
            {...cardProps}
          />
        )
      }
    })
  }

  render() {
    return (
      <VehicleListingWrapper>
        {this._renderTitle()}
        <Container>{this._renderVehicleCards()}</Container>
      </VehicleListingWrapper>
    )
  }
}

export default VehicleListing
