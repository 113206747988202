import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import helpers from '../../util/helpers'

const CardWrapper = styled.div`
  width: 24%;
  margin-right: 1%;
  margin-bottom: 2rem;
  position: relative;
  top: 0;
  transition: top 0.3s ease-in-out;
  @media (max-width: ${props => props.theme.sizes.tablet}) {
    width: 48%;
    &:nth-child(n + 1) {
      margin-right: 2% !important;
    }
  }
  @media (max-width: ${props => props.theme.sizes.mobile}) {
    width: 100%;
    margin-right: 0 !important;
  }
  &:hover {
    top: -5px;
  }
  .wrap {
    background: #ffffff;
    box-shadow: 0 5px 0 0 ${props => props.theme.colors.red},
      1px 2px 11px 0 rgba(0, 0, 0, 0.5);

    &:nth-child(4n) {
      margin-right: 0;
    }
    .top {
      background: ${props => props.theme.colors.grey};
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }
    p {
      margin: 0;
      text-align: center;
    }
    .top {
      position: relative;
      height: 160px;
      overflow: hidden;
      @media (max-width: ${props => props.theme.sizes.mobile}) {
        height: 220px;
      }
    }
    .bottom {
      div {
        padding: 0.5rem 1rem;

        &:nth-child(2n) {
          margin-right: 0;
          background: ${props => props.theme.colors.greyLight};
        }
      }
    }
  }
`
const CardImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 160px;
  @media (max-width: ${props => props.theme.sizes.mobile}) {
    height: 220px;
  }

  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`

class VehicleCard extends React.Component {
  static propTypes = {
    image: PropTypes.object.isRequired,
    vehicleName: PropTypes.string.isRequired,
  }

  render() {
    let { image, vehicleName } = this.props
    return (
      <CardWrapper>
        <div className="wrap">
          <Link to={helpers.formatVehicleSlug(vehicleName)} />
          <div className="top">
            <CardImg fluid={image} backgroundColor="#232324" />
          </div>
          <div className="bottom">
            {vehicleName && (
              <div>
                <p>{vehicleName}</p>
              </div>
            )}
          </div>
        </div>
      </CardWrapper>
    )
  }
}

export default VehicleCard
