const helpers = {
  formatVehicleSlug: string => {
    let slug = `/vehicle/${string
      .replace(/ /g, '-')
      .replace(/[,&]/g, '')
      .toLowerCase()}/`
    return slug
  },
}

export default helpers
