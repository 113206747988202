import React, { Fragment } from 'react'
import Hero from '../components/UI/Hero'
import SEO from '../components/SEO'
import VehicleListing from '../components/UI/VehicleListing'
import { graphql } from 'gatsby'
import _ from 'underscore'

export default class FleetCategoryTemplate extends React.Component {
  _getImage = type => {
    let { name } = this.props.pageContext
    let { edges } = this.props.data.fleetImages
    let currentFleet = _.filter(edges, a => {
      return a.node.data.name === name
    })
    let image
    switch (type) {
      case 'background':
        image =
          currentFleet[0].node.data.backgroundImage.localFiles[0]
            .childImageSharp.fluid
        break
      case 'icon':
        image =
          currentFleet[0].node.data.icon.localFiles[0].childImageSharp.fixed
        break
      default:
        image = null
    }
    return image
  }
  _renderVehicleListing = () => {
    let { name } = this.props.pageContext
    let { edges } = this.props.data.fleetVehicles
    let currentVehicles = _.filter(edges, a => {
      return _.some(a.node.data.vehicleType, b => {
        return b.data.name === name
      })
    })
    return <VehicleListing vehicles={currentVehicles} />
  }
  render() {
    let { name } = this.props.pageContext
    return (
      <Fragment>
        <SEO title={name} />
        <Hero
          heroStyle="fleet"
          title={name}
          background={this._getImage('background')}
          icon={this._getImage('icon')}
        />
        {this._renderVehicleListing()}
      </Fragment>
    )
  }
}

export const fleetQuery = graphql`
  query {
    fleetImages: allAirtable(filter: { table: { eq: "Fleets" } }) {
      edges {
        node {
          data {
            name
            icon {
              localFiles {
                childImageSharp {
                  fixed(width: 40) {
                    ...GatsbyImageSharpFixed_tracedSVG
                  }
                }
              }
            }
            backgroundImage {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    fleetVehicles: allAirtable(filter: { table: { eq: "Vehicles" } }) {
      edges {
        node {
          data {
            vehicleImages {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            active
            vehicleMake
            vehicleModel
            vehicleYear
            vehicleName
            vehicleType {
              data {
                name
              }
            }
          }
        }
      }
    }
  }
`
